import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginService from "../../services/LoginService";
import "bootstrap/dist/css/bootstrap.min.css";

import "./sidebar.scss";
import { UserType } from "../../enums/UserType";

const sidebarNavItems = [
  {
    display: "Vendas",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/home",
    section: "",
  },
];
const adminNavItems = [
  {
    display: "Relatório",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/report",
    section: "",
  },
  {
    display: "Usuários",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-users",
    section: "",
  },
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [login, setLogin] = useState(window.sessionStorage.getItem("hash"));
  const [type, setType] = useState(window.sessionStorage.getItem("type"));

  const navigate = useNavigate();

  const handleLogout = () => {
    loginService.logout();
    window.sessionStorage.removeItem("hash");
    window.sessionStorage.removeItem("type");
    window.sessionStorage.removeItem("firmName");
    window.sessionStorage.removeItem("firmId");
    setLogin(null);
    navigate("/");
  };

  useEffect(() => {
    const currentPath = window.location.pathname.split("/")[1];
    const activeItem = adminNavItems.findIndex(
      (item) => item.to.indexOf(currentPath) >= 0
    );
    setActiveIndex(currentPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const handleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const closeSidebar = () => {
    eval("document.getElementsByClassName('link-collapse')[0].click()");
  };

  return (
    <div className="sidebar" style={{ left: collapsed ? -250 : 0 }}>
      {collapsed && (
        <>
          <a
            href="javascript: void(0)"
            onClick={() => handleSidebar()}
            className="link-collapse btn btn-primary"
          >
            Menu
          </a>
        </>
      )}

      {!collapsed && (
        <>
          <a
            href="javascript: void(0)"
            onClick={() => handleSidebar()}
            className="link-collapse"
          >
            Fechar
          </a>
          <div
            className="sidebar__menu"
            style={{ borderRight: "1px solid #006699" }}
          >
            <>
              <div className="sidebar__menu__indicator"></div>
              {type !== UserType.ADMIN &&
                sidebarNavItems.map((item, index) => (
                  <Link to={item.to} key={index} onClick={() => closeSidebar()}>
                    <div
                      className={`sidebar__menu__item ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      <div className="sidebar__menu__item__icon">
                        {item.icon}
                      </div>
                      <div className="sidebar__menu__item__text">
                        {item.display}
                      </div>
                    </div>
                  </Link>
                ))}
              {type === UserType.ADMIN &&
                adminNavItems.map((item, index) => (
                  <Link to={item.to} key={index} onClick={() => closeSidebar()}>
                    <div
                      className={`sidebar__menu__item ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      <div className="sidebar__menu__item__icon">
                        {item.icon}
                      </div>
                      <div className="sidebar__menu__item__text">
                        {item.display}
                      </div>
                    </div>
                  </Link>
                ))}
            </>
          </div>
          <div className="login">
            {!login ? (
              <Link to="/login" className="btn btn-primary">
                Logar
              </Link>
            ) : (
              <button onClick={handleLogout} className="btn btn-primary ">
                Sair
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;

import api from "./api"

class SaleService{

    async getReport(): Promise<any>{   
        return api.get(`/sales/report/all`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }


    async getAll(): Promise<any>{   
        return api.get(`/reminders`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
    
}

const saleService = new SaleService()
export default saleService


import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Button, Form } from "react-bootstrap";
import loginService from "../../services/LoginService";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [path, setPath] = useState(location.state?.path);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let data = {
      username: usuario,
      password: password,
    };

    loginService
      .login(data)
      .then((response) => {
        const hash = response.data.access_token;
        window.sessionStorage.setItem("hash", hash);
        const type = response.data.type;
        window.sessionStorage.setItem("type", type);
        if (path) {
          navigate(path);
        } else {
          navigate("/report");
        }
      })
      .catch((error) => {
        alert("Usuário não encontrado");
      });
  };

  return (
    <>
      <div className="form-div">
        <div className="form-header">
          <h1 className="header-login">Login</h1>
        </div>
        <div className="form-body">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-5">
              <Form.Control
                type="text"
                placeholder="Usuário"
                onChange={(e) => setUsuario(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Control
                type="password"
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" className="button-send">
              Entrar
            </Button>
          </Form>
        </div>
        <div className="form-footer"></div>
      </div>
    </>
  );
};
export default Login;

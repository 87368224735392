import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert, Row, Col } from "react-bootstrap";
// import api from "../../services/api";
// import { BsFillExclamationCircleFill, BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
// import moment from 'moment';
// import UtilDate from "../../utils/util.date";
// import IImportData from "../../interfaces/IImportData";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
// import IStore from "../../interfaces/IStore";
// import ISortType from "../../interfaces/ISortType";
// import customerService from "../../services/CustomerService";
// import { useNavigate } from "react-router-dom";
// import ICustomer from "../../interfaces/ICustomer";
// import { PatternFormat } from "react-number-format";
// import IFirm from "../../interfaces/IFirm";
// import firmService from "../../services/FirmService";
// import userService from "../../services/UserService";
// import IUser from "../../interfaces/IUser";
// import { UserType } from "../../enums/UserType";
import saleService from "../../services/SaleService";
import IResultName from "./interfaces/IResultName";
import IReportData from "./interfaces/IReportData";
import TableComponent from "./table";
import TableComponentTotalClients from "./tableTotalClients";

registerLocale("pt-BR", ptBR);

const Report = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  // const navigate = useNavigate();

  const [customersBySeller, setCustomersBySeller] = useState<IResultName[]>([])
  const [remindersBySeller, setRemindersBySeller] = useState<IResultName[]>([])
  const [remindersLateBySeller, setRemindersLateBySeller] = useState<IResultName[]>([])
  const [remindersSolvedBySeller, setRemindersSolvedBySeller] = useState<IResultName[]>([])
  const [remindersTodayBySeller, setRemindersTodayBySeller] = useState<IResultName[]>([])
  const [stagesWithMoreLosses, setStagesWithMoreLosses] = useState<IResultName[]>([])
  const [remindersRescheduledPerSeller, setReschedulePerSeller] = useState<IResultName[]>([])
  const [stagesWithMoreSales, setStagesWithMoreSales] = useState<IResultName[]>([])


  const loadList = () => {
    setLoading(true);
    saleService
      .getReport()
      .then((response) => {
        let result: IReportData = response.data
        // console.log(result.customersBySeller)
        setCustomersBySeller(result.customersBySeller)
        setRemindersBySeller(result.remindersBySeller)
        setRemindersLateBySeller(result.remindersLateBySeller)
        setRemindersSolvedBySeller(result.remindersSolvedBySeller)
        setRemindersTodayBySeller(result.remindersTodayBySeller)
        setReschedulePerSeller(result.remindersRescheduledPerSeller)
        setStagesWithMoreLosses(result.stagesWithMoreLosses)
        setStagesWithMoreSales(result.stagesWithMoreSales)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false);
      });

      // reminderService
      // .getRemindersSolved()
      // .then((response) => {
      //   let result: IReportData = response.data
      //   setRemindersSolvedBySeller(result.remindersSolvedBySeller)
      // })
      // .catch((error) => {
      //   setLoading(false)
      // })

  };

  useEffect(() => {
    loadList();
  }, []);

  // const testeApp = () => {

  //     saleService
  //     .getAll()
  //     .then((response) => {
  //       let result2 : IReportData = response.data;
  //       console.log(result2)
  //     })

  // }


  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            {/* <div className="title">
              <h3>Relatório</h3>
            </div> */}

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            { !isLoading && 
              <Button className="btn btn-primary m-2" onClick={loadList}>
                Atualizar dados
              </Button>
            }
            { isLoading && 
              <span>Carregando...</span>
            }
          </div>
          
          <br />
          <Row>
            <Col>
              <TableComponentTotalClients data={customersBySeller}  title="Total de Clientes por Vendedor" loading={isLoading} />
            </Col>
            <Col>
              <TableComponent data={remindersBySeller} title="Total de Avisos por Vendedor" loading={isLoading} />
            </Col>
          </Row>
          <Row>
            <Col>
              <TableComponent data={remindersLateBySeller} title="Total de Avisos Atrasados por Vendedor" loading={isLoading} />
            </Col>
            <Col>
              <TableComponent data={remindersTodayBySeller} title="Total de Avisos de Hoje por Vendedor" loading={isLoading} />
            </Col>
          </Row>
          <Row>
            <Col>
            <TableComponent data={remindersSolvedBySeller} title="Total de Avisos Solucionados por Vendedor" loading={isLoading} />
            </Col>
            <Col>
            <TableComponent data={remindersRescheduledPerSeller} title="Total de Reagendamentos por Vendedor" loading={isLoading} />
            </Col>
          </Row>
          <Row>
            <Col>
              <TableComponent data={stagesWithMoreLosses} title="Etapas com mais Perdas de Vendas" loading={isLoading} />
            </Col>
            <Col>
              <TableComponent data={stagesWithMoreSales} title="Etapas com mais Ganhos de Vendas" loading={isLoading} />
            </Col>
          </Row>
          <br />
          {/* <button onClick={() => testeApp()}>
            ola
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Report;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flatlist {
  margin-bottom: 60px;
}

.main-container {
  margin-left: 100px;
  width: calc(100vw - 100px);
}

.button-top-left {
  align-self: flex-start;
  margin: 10px;
}

.button-top-right {
  align-self: flex-end;
  margin: 10px;
}

.modal-large {
  width: 60%;
  margin-left: 20%;
}

.modal-large .modal-dialog {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/global.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,kBAAA;EACA,0BAAA;AAEJ;;AAAA;EACI,sBAAA;EACA,YAAA;AAGJ;;AADA;EACI,oBAAA;EACA,YAAA;AAIJ;;AAFA;EACI,UAAA;EACA,gBAAA;AAKJ;;AAHA;EACI,eAAA;AAMJ","sourcesContent":[".flatlist{\n    margin-bottom: 60px;\n}\n.main-container{\n    margin-left: 100px;\n    width: calc(100vw - 100px)\n}\n.button-top-left{\n    align-self: flex-start;\n    margin: 10px;\n}\n.button-top-right{\n    align-self: flex-end;\n    margin: 10px;\n}\n.modal-large{\n    width: 60%;\n    margin-left: 20%;\n}\n.modal-large .modal-dialog{\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

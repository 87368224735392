import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
// import api from "../../services/api";
// import { BsFillExclamationCircleFill, BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
// import moment from 'moment';
import UtilDate from "../../utils/util.date";
// import IImportData from "../../interfaces/IImportData";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
// import IStore from "../../interfaces/IStore";
// import ISortType from "../../interfaces/ISortType";
// import customerService from "../../services/CustomerService";
// import { useNavigate } from "react-router-dom";
// import ICustomer from "../../interfaces/ICustomer";
import { PatternFormat } from "react-number-format";
// import IFirm from "../../interfaces/IFirm";
// import ICustomer from "../../interfaces/ICustomer";
// import firmService from "../../services/FirmService";
import userService from "../../services/UserService";
import customerService from "../../services/CustomerService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";

registerLocale("pt-BR", ptBR);

const UserList = () => {
  const [items, setItems] = useState<IUser[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  // const [customers, setCustomers] = useState<ICustomer[]>([]);
  // const [customersIds, setCustomersIds] = useState<number[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<number>();
  const [idNewSeller, setIdNewSeller] = useState<number>();
  const [document, setDocument] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordCompare, setPasswordCompare] = useState<string>();
  const [type, setType] = useState<string | undefined>("ADMIN");
  const [phone, setPhone] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  // const navigate = useNavigate();

  const loadList = () => {
    setLoading(true);
    userService
      .getAll()
      .then((response) => {
        let users: IUser[] = response.data;
        setItems(users);
        setLoading(false);

        // customerService.getAll().then((response) => {
        //   let allCustomers: ICustomer[] = response.data;

        //   setCustomers(allCustomers);
        //   setLoading(false);

        // });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const handleShowUpdate = (user: IUser) => {
    setUserId(user.id);
    setDocument(user.document);
    setName(user.name);
    setEmail(user.email);
    setUsername(user.username);
    setType(user.type);
    setPhone(user.phone);
    setPassword("");
    setPasswordCompare("");
    handleShow();
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (email && emailRegex.test(email)) {
      let data = {
        name: name,
        email: email,
        username: username,
        type: type,
        phone: phone,
        document: document,
        password,
      };
      if (password === passwordCompare) {
        if (userId) {
          userService
            .update(data, userId)
            .then((response) => {
              loadList();
              handleClose();
              setUserId(undefined);
            })
            .catch((error) => {
              setError(true);
            });
        } else {
          data.password = password;
          userService
            .create(data)
            .then((response) => {
              loadList();
              handleClose();
            })
            .catch(() => {
              setError(true);
            });
        }
      } else {
        setPassError(true);
      }
    } else {
      setEmailError(true);
    }
  };

  const handleDelete = async (id: number) => {
    userService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        alert(
          "Não foi possível excluir o usuário porque possui dados vinculados no sistema"
        );
      });
  };

  const handleClose = () => {
    setDocument(undefined);
    setName(undefined);
    setEmail(undefined);
    setUsername(undefined);
    setUserId(undefined);
    setPassword(undefined);
    setType(undefined);
    setPhone(undefined);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
    setPassError(false);
  };

  // const handleCheckboxProducts = (input: any) => {
  //   let list = firmsIds
  //   if (input.checked) { //se o checkbox foi marcado, adiciona o ID do produto na lista de selecionados
  //     list = [...list, Number(input.value)]
  //   } else { //se o checkbox foi desmarcado, remove o ID do produto da lista de selecionados
  //     list = list.filter((item) => item != input.value)
  //   }
  //   setFirmsIds(list)
  // }

  const handleAdd = () => {
    const body = {
      idNewSeller: idNewSeller,
      idOldSeller: userId,
    };

    if (body.idNewSeller && body.idOldSeller) {
      customerService
        .migrateClients(body)
        .then((response) => {
          let result = response.data;
          if (result) {
            alert("Alteração realizada com sucesso!");
            setShowUsers(false);
          }
        })
        .catch((error) => {
          alert("Houve um erro ao atualizar o vendedor!");
        });
    }
    alert("Houve um erro ao selecionar o(s) vendedor(es)");

    // let firmsToSave: any[] = []
    // for(let item of firmsIds){
    //   let firm = firms.find((firm) => firm.id == item)
    //   firmsToSave.push(firm)
  };

  //   let data = {
  //     firms: firmsToSave
  //   }
  //   if(userId){
  //     userService.updateFirms(data, userId).then(() => {
  //       setUserId(undefined)
  //       handleCloseFirms()
  //       loadList()
  //     }).catch((error) => {
  //       alert("Houve um erro ao vincular às empresas ao usuário")
  //     })
  //   }

  // }

  const handleCloseUsers = () => {
    // setUserId(undefined)
    // setFirmsIds([])
    setShowUsers(false);
  };

  const getUserType = (type: string) => {
    const types: { [key: string]: string } = {
      ADMIN: "Administrador",
      SELLER: "Vendedor",
    };
    return types[type];
  };

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Usuários</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar usuário
            </Button>
          </div>

          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th>Nome</th>
                <th>Usuário</th>
                <th>Tipo</th>
                <th>Criado em</th>
                <th>Documento</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((user: IUser) => (
                <tr key={user.id}>
                  <td style={{ width: 50 }}>
                    <span>{user.id}</span>
                  </td>
                  <td>
                    <span>{user.name}</span>
                  </td>
                  <td>
                    <span>{user.username}</span>
                  </td>
                  <td style={{ width: 120 }}>
                    <span>{getUserType(user.type)}</span>
                  </td>
                  <td style={{ width: 120 }}>
                    <span>
                      {UtilDate.stringToDMYHM(user.created_at.toString())}
                    </span>
                  </td>
                  <td>
                    <span>
                      <PatternFormat
                        format="###.###.###-##"
                        displayType="text"
                        value={user.document}
                        allowEmptyFormatting
                        mask="_"
                      />
                    </span>
                  </td>
                  {/* <td style={{minWidth: 250}}>
                      {user.firms?.length > 0 &&
                        <span>{user.firms?.map((firm) => firm.name).join(",")}</span>
                      }
                      {user.firms?.length == 0 &&
                        <span>Nenhuma empresa vinculada</span>
                      }
                    </td> */}
                  <td style={{ width: 400 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(user)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-secondary"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => {
                        setUserId(user.id);
                        setShowUsers(true);
                      }}
                    >
                      Migrar Clientes
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleDelete(user.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items.length == 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum usuário encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Usuário</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome de usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Confirme a senha
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={passwordCompare}
                onChange={(e) => setPasswordCompare(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Documento</Form.Label>
              <PatternFormat
                format="###.###.###-##"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values;
                  setDocument(value);
                }}
                step={0.1}
                className="form-control"
                name="tax"
                placeholder="000.000.000-00"
                value={document}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Telefone</Form.Label>
              <PatternFormat
                format="(##)#####-####"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values;
                  setPhone(value);
                }}
                step={0.1}
                className="form-control"
                name="tax"
                placeholder="(00)00000-0000"
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Tipo</Form.Label>
              <Form.Select onChange={(e) => setType(e.target.value)}>
                <option value={UserType.ADMIN}>Administrador</option>
                <option value={UserType.SELLER}>Vendedor</option>
              </Form.Select>
            </Form.Group>
          </Form>
          {passError && (
            <Alert
              variant="danger"
              onClose={() => setPassError(false)}
              dismissible
            >
              As senhas não coincidem
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Usuário já cadastrado
            </Alert>
          )}
          {emailError && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              E-mail inválido
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setUserId(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUsers} onHide={handleCloseUsers}>
        <Modal.Header closeButton>
          <Modal.Title>Migrar Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <select
              id="selectmultiple"
              name="selectmultiple"
              className="form-control"
              onChange={(e) => {
                setIdNewSeller(parseInt(e.target.value));
              }}
            >
              {items.map((user: IUser) => {
                return user.type === "SELLER" ? (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ) : (
                  ""
                );
              })}
            </select>
            {/* {
                items.map((user: IUser) => (
                  <tr key={user.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        // id={user.id}
                        label={''}
                        value={user.id}
                        // onChange={e => handleCheckboxProducts(e.target)}
                        // checked={firmsIds.includes(firm.id)}
                      />
                    </td>
                    <td><span>{user.name}</span></td>
                  </tr>
                ))} */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUsers}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleAdd()}
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserList;

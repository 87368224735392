import api from "./api";

class CustomerService {
  // async getAll(): Promise<any> {
  //   return api
  //     .get(`/customers/all`)
  //     .then((result) => {
  //       return Promise.resolve(result);
  //     })
  //     .catch((error) => {
  //       return Promise.reject(error);
  //     });
  // }

  async migrateClients(data: any): Promise<any> {
    return api({
      url: `/customers/migrateClients`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const customerService = new CustomerService();

export default customerService;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  height: 50vh;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logo {
  align-self: center;
}

.header-login {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  top: -19px;
  text-align: center;
  margin-bottom: 20%;
  /* identical to box height*/
  color: rgba(253, 81, 0, 0.5);
}

.button-send {
  border-radius: 10px;
  width: 100%;
  background: rgba(253, 81, 0, 0.9);
  border: 1px solid #fd5100;
}

.button-send:hover {
  background: rgba(253, 81, 0, 0.7);
  border: 1px solid #fd5100;
  border-radius: 10px;
}

.button-cancel {
  border-radius: 10px;
  width: 100%;
  background: #ccc;
  border: 1px solid #ccc;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,qCAAA;EACA,2CAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EAEA,aAAA;EACA,qBAAA;EAEA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,2BAAA;EACA,4BAAA;AADF;;AAIA;EACE,mBAAA;EACA,WAAA;EACA,iCAAA;EACA,yBAAA;AADF;;AAIA;EACE,iCAAA;EACA,yBAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;AADF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n}\n\n.form-div {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 400px;\n  margin: auto;\n  margin-top: 60px;\n  align-items: center;\n  justify-content: center;\n  height: 50vh;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}\n\n.logo {\n  align-self: center;\n}\n\n.header-login {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 26px;\n  line-height: 31px;\n  /* identical to box height */\n\n  display: flex;\n  align-items: flex-end;\n\n  top: -19px;\n  text-align: center;\n  margin-bottom: 20%;\n  /* identical to box height*/\n  color: rgba(253, 81, 0, 0.5);\n}\n\n.button-send {\n  border-radius: 10px;\n  width: 100%;\n  background: rgba(253, 81, 0, 0.9);\n  border: 1px solid #fd5100;\n}\n\n.button-send:hover {\n  background: rgba(253, 81, 0, 0.7);\n  border: 1px solid #fd5100;\n  border-radius: 10px;\n}\n\n.button-cancel {\n  border-radius: 10px;\n  width: 100%;\n  background: #ccc;\n  border: 1px solid #ccc;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Table } from "react-bootstrap";
import IResultName from "./interfaces/IResultName";
import { useEffect, useState } from "react";

interface ITableProps {
  data: IResultName[];
  loading?: boolean;
  title: string;
}

const TableComponentTotalClients = (props: ITableProps) => {
  const [items, setItems] = useState<IResultName[]>(props.data);
  const [isLoading, setLoading] = useState<boolean>(
    props.loading ? props.loading : false
  );

  useEffect(() => {
    setItems(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.loading !== undefined) {
      setLoading(props.loading);
    }
  }, [props.loading]);

  return (
    <>
      <h2>{props.title}</h2>
      <Table striped bordered hover>
        <thead>
          <tr className="tr">
            <th className="th" style={{ width: "50px" }}>Total</th>
            <th className="th" style={{ width: "50px" }}>Últimos 7 dias</th>
            <th className="th">Nome</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={3}>
                <p>Carregando...</p>
              </td>
            </tr>
          )}
          {items.map((item: IResultName) => (
            <tr key={String(item.id)}>
              <td>
                <span>{item.total}</span>
              </td>
              <td>
                <span>{item.totalLast7Days}</span>
              </td>
              <td>
                <span>{item.name}</span>
              </td>
            </tr>
          ))}
          {items.length == 0 && !isLoading && (
            <tr>
              <td colSpan={3}>
                <p>Nenhum dado encontrado.</p>
              </td>
            </tr>
          )}
        </tbody>        
      </Table>
    </>
  );
};

export default TableComponentTotalClients;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  margin-left: 110px;
  background-color: #ccc;
  padding: 10px;
  width: 500px;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/applayout.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;AACJ","sourcesContent":[".header{\n    margin-left: 110px;\n    background-color: #ccc;\n    padding: 10px;\n    width: 500px;\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

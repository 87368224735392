
import { Outlet } from "react-router-dom"
import Sidebar from "../sidebar/Sidebar"
import "./applayout.scss";

const AppLayout = () => {

    return <div>
        <Sidebar />
        <div>
            <Outlet />
        </div>
    </div>
}

export default AppLayout